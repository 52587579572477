import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'BAPE-ETH LP',
    lpAddresses: {
      8453: '0x9f55d053B63d8351D436eA3F22b1F92D587F0899',
      97: '0x9f55d053B63d8351D436eA3F22b1F92D587F0899',
    },
    tokenSymbol: 'BAPE',
    tokenAddresses: {
      8453: '0x6450C2797Fbf750Ec27EdbB48386142645D3C354',
      97: '0x6450C2797Fbf750Ec27EdbB48386142645D3C354',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    risk: 3,
    lpSymbol: 'BAPE-USDC LP',
    lpAddresses: {
      97: '0x40856CE22cB8075774A99b3287A7dD3d7e89bE91',
      8453: '0x40856CE22cB8075774A99b3287A7dD3d7e89bE91',
    },
    tokenSymbol: 'BAPE',
    tokenAddresses: {
      97: '0x6450C2797Fbf750Ec27EdbB48386142645D3C354',
      8453: '0x6450C2797Fbf750Ec27EdbB48386142645D3C354',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    risk: 1,
    lpSymbol: 'WETH-USDC LP',
    lpAddresses: {
      97: '0x88A43bbDF9D098eEC7bCEda4e2494615dfD9bB9C',
      8453: '0x88A43bbDF9D098eEC7bCEda4e2494615dfD9bB9C',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      97: '0x4200000000000000000000000000000000000006',
      8453: '0x4200000000000000000000000000000000000006',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 3,
    risk: 2,
    lpSymbol: 'USDC/USDbC LP',
    lpAddresses: {
      97: '0xC5ACcD3e4f6dF1912498775807e0972B3ec43F29',
      8453: '0xC5ACcD3e4f6dF1912498775807e0972B3ec43F29',
    },
    tokenSymbol: 'USDCbC',
    tokenAddresses: {
      97: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
      8453: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 4,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'BAPE',
    lpAddresses: {
      97: '0x40856CE22cB8075774A99b3287A7dD3d7e89bE91',
      8453: '0x40856CE22cB8075774A99b3287A7dD3d7e89bE91', 
    },
    tokenSymbol: 'BAPE',
    tokenAddresses: {
      97: '0x6450C2797Fbf750Ec27EdbB48386142645D3C354',
      8453: '0x6450C2797Fbf750Ec27EdbB48386142645D3C354',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  }, 
  {
    pid: 5,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WETH',
    lpAddresses: {
      97: '0x88A43bbDF9D098eEC7bCEda4e2494615dfD9bB9C',
      8453: '0x88A43bbDF9D098eEC7bCEda4e2494615dfD9bB9C', 
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      97: '0x4200000000000000000000000000000000000006',
      8453: '0x4200000000000000000000000000000000000006',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  }, 
  {
    pid: 7,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDT',
    lpAddresses: {
      97: '0xCc8643Deb338A18Bb35F9F5125684863A501AB96',
      8453: '0xCc8643Deb338A18Bb35F9F5125684863A501AB96', 
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0xe83be19De825D0242211823AcAc23754BbC9bea0',
      8453: '0xe83be19De825D0242211823AcAc23754BbC9bea0',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  }, 
    /**
  {
    pid: 14,
    risk: 2,
    lpSymbol: 'ETH-BNB LP',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
   /**
  {
    pid: 16,
    risk: 1,
    lpSymbol: 'DAI-BUSD LP',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 17,
    risk: 1,
    lpSymbol: 'USDC-BUSD LP',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 18,
    risk: 3,
    lpSymbol: 'DOT-BNB LP',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    tokenSymbol: 'DOT',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 19,
    risk: 4,
    lpSymbol: 'CAKE-BUSD LP',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 20,
    risk: 4,
    lpSymbol: 'CAKE-BNB LP',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'BAPE',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead', // BAPE-BUSD LP
    },
    tokenSymbol: 'BAPE',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead', // BAPE-BUSD LP (BUSD-BUSD will ignore)
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 3,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WBNB',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead', // BNB-BUSD LP
    },
    tokenSymbol: 'WBNB',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 4,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDT',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead', // USDT-BUSD LP
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 5,
    risk: 2,
    isTokenOnly: true,
    lpSymbol: 'BTCB',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead', // BTCB-BUSD LP
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 6,
    risk: 2,
    isTokenOnly: true,
    lpSymbol: 'ETH',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead', // ETH-BUSD LP
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 7,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'DAI',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead', // DAI-BUSD LP
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 19,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDC',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead', // USDC-BUSD LP
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 8,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'DOT',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead', // DOT-BUSD LP
    },
    tokenSymbol: 'DOT',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 9,
    risk: 4,
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead', // CAKE-BUSD LP
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      8453: '0x000000000000000000000000000000000000dead',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  */
   
]

export default farms
