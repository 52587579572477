export default {
  cake: {
    8453: '0x6450C2797Fbf750Ec27EdbB48386142645D3C354',
    97: '0x6450C2797Fbf750Ec27EdbB48386142645D3C354',
  },
  masterChef: {
    8453: '0x7888602E88E82178c62DAb198B030582aF370a36',
    97: '0x7888602E88E82178c62DAb198B030582aF370a36',
  },
  wbnb: {
    8453: '0x4200000000000000000000000000000000000006',
    97: '0x4200000000000000000000000000000000000006',
  },
  lottery: {
    8453: '',
    97: '',
  },
  lotteryNFT: {
    8453: '',
    97: '',
  },
  mulltiCall: {
    8453: '0x8Bf13aEBE6427EE49fc3a8013AE140DBE85406Cd',
    97: '0x8Bf13aEBE6427EE49fc3a8013AE140DBE85406Cd',
  },
  busd: {
    8453: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
    97: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
  },
}
