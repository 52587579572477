import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Mining',
    icon: 'PoolIcon',
    href: '/mining',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
    label: 'Buy/Add Liq',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Buy on Uniswap',
        href: 'https://app.uniswap.org/?intro=true',
      },
      {
        label: 'Add LP BAPE-ETH ',
        href: 'https://app.uniswap.org/add/v2/ETH/0x6450c2797fbf750ec27edbb48386142645d3c354',
      },
      {
        label: 'Add LP BAPE-USDC ',
        href: 'https://app.uniswap.org/add/v2/0x833589fcd6edb6e08f4c7c32d4f71b54bda02913/0x6450c2797fbf750ec27edbb48386142645d3c354',
      },
      {
        label: 'Add LP WETH-USDC ',
        href: 'https://app.uniswap.org/add/v2/ETH/0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
      },
      {
        label: 'Add LP USDC/USDbC ',
        href: 'https://app.uniswap.org/add/v2/0x833589fcd6edb6e08f4c7c32d4f71b54bda02913/0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Twitter',
        href: 'https://x.com/BapeFarm',
      },
      {
        label: 'Telegram',
        href: 'https://t.me/BapeFiPortal/',
      },
      {
        label: 'Docs',
        href: 'https://bapefi.gitbook.io/bapefi',
      },
    ],
  },
]

export default config
